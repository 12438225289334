<template>
  <div class="manage-booking-table">
    <h3 class="py-3">Booked By Others</h3>

    <div class="card">
      <vue-good-table
        mode="remote"
        @on-page-change="onPageChange"
        @on-per-page-change="onPerPageChange"
        :columns="bookingTableColumns"
        :rows="bookingTableData"
        :totalRows="bookingTablePagination.total"
        :isLoading="isLoading"
        :pagination-options="{
          enabled: true,
          mode: 'pages',
          perPage: 30,
          perPageDropdown: perPageOptions,
          dropdownAllowAll: false
        }"
        :sort-options="{
          enabled: false
        }"
        @on-row-click="toBookingDetail"
      >
        <div slot="loadingContent">
          <div class="d-flex justify-content-center">
            <spinner></spinner>
          </div>
        </div>
        <div slot="emptystate">
          <no-data message="There is no bookings"></no-data>
        </div>
        <div slot="table-actions" class="p-2"></div>
        <template slot="table-row" slot-scope="props">
          <!-- ========================= ACTION ========================== -->
          <span v-if="props.column.field == 'action'" @click.stop>
            <div class="d-flex">
              <button
                class="btn main mr-1"
                @click="openApproveModal(props.row.id)"
              >
                Approve
              </button>
              <dropdown-button buttonClass="bordered main">
                <template #buttonContent>
                  <i class="fas fa-ellipsis-v"></i>
                </template>
                <div class="card text-left">
                  <fd-list-item
                    class="cursor-pointer unselectable"
                    @click="openRejectModal(props.row.id)"
                  >
                    <i class="fas fa-hand-paper mr-1"></i>
                    Reject Booking
                  </fd-list-item>
                  <fd-list-item
                    class="cursor-pointer unselectable"
                    @click="openCancelModal(props.row.id)"
                  >
                    <i class="fas fa-times mr-1"></i>
                    Cancel Booking
                  </fd-list-item>
                </div>
              </dropdown-button>
            </div>
          </span>
          <!-- ================== PROJECT UNIT DETAILS =================== -->
          <span v-else-if="props.column.field == 'project'">
            <div class="row mt-1 project-detail">
              <div class="col-4 mb-2 pr-2">
                <img
                  class="main-photo"
                  :src="$getFileURL(props.row.project.mainPhoto[0])"
                  :alt="props.row.project.name"
                  @error="$setAltPhoto"
                  loading="lazy"
                />
              </div>

              <div class="col-8">
                <p class="title mb-1">
                  <span class="plot mr-1">
                    {{ props.row.projectUnit.plot }}
                  </span>
                  {{ props.row.projectUnit.street }}
                </p>
                <p class="font-bold context">{{ props.row.project.name }}</p>
                <p class="context">{{ props.row.projectUnitType.name }}</p>
                <p class="context">
                  {{ props.row.projectUnitFloorPlan.name }}
                </p>
              </div>
            </div>
          </span>
          <!-- ====================== BUYER CONTACT ====================== -->
          <span
            v-else-if="props.column.field == 'buyerContact'"
            class="buyer-contact"
            @click.stop
          >
            <span class="d-flex align-items-center">
              <i class="fas fa-phone mr-1"></i
              >{{ props.row.projectPurchaseReservation.phoneContact }}
            </span>
            <span class="d-flex align-items-center">
              <i class="fas fa-envelope mr-1"></i
              >{{ props.row.projectPurchaseReservation.email }}
            </span>
          </span>
          <!-- ======================== AGENT INFO ======================= -->
          <span
            v-else-if="
              props.column.field == 'assignedAgent' &&
              !_.isEmpty(props.row.agent)
            "
            @click.stop
          >
            <p>{{ props.row.agent.name }}</p>
            <span class="d-flex align-items-center">
              <i class="fas fa-phone mr-1"></i
              >{{ $getUserPhoneNumber(props.row.agent.user) }}
            </span>
            <span class="d-flex align-items-center">
              <i class="fas fa-envelope mr-1"></i
              >{{ props.row.agent.user.email }}
            </span>
          </span>
          <!-- =========================================================== -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>
      </vue-good-table>
    </div>
    <!-- ===================== Modal: Approve Booking ====================== -->
    <modal v-model="approveBookingModal.isShown">
      <approve-booking
        :bookingId="approveBookingModal.id"
        @cancel="approveBookingModal.isShown = false"
        @submit="approveBooking"
      ></approve-booking>
    </modal>
    <!-- ===================== Modal: Reject Booking ======================= -->
    <modal v-model="rejectBookingModal.isShown">
      <reject-booking
        :bookingId="rejectBookingModal.id"
        @cancel="rejectBookingModal.isShown = false"
        @submit="rejectBooking"
      ></reject-booking>
    </modal>
    <!-- ===================== Modal: Cancel Booking ======================= -->
    <modal v-model="cancelBookingModal.isShown">
      <cancel-booking
        :bookingId="cancelBookingModal.id"
        @cancel="cancelBookingModal.isShown = false"
        @submit="cancelBooking"
      ></cancel-booking>
    </modal>
  </div>
</template>

<script>
import vueGoodTable from "@/mixins/vue-good-table/mixin";
import { projectBookingStatus } from "@/enums";

export default {
  components: {
    Spinner: () =>
      import("@/components/GlobalComponents/LoaderComponent/Spinner"),
    DropdownButton: () =>
      import("@/components/GlobalComponents/DropdownButton"),
    FdListItem: () => import("@/components/GlobalComponents/List/FdListItem"),
    // Modal Contents
    ApproveBooking: () =>
      import(
        "@/components/GlobalComponents/Shared/ProjectSales/Booking/ApproveBooking"
      ),
    RejectBooking: () =>
      import(
        "@/components/GlobalComponents/Shared/ProjectSales/Booking/RejectBooking"
      ),
    CancelBooking: () =>
      import(
        "@/components/GlobalComponents/Shared/ProjectSales/Booking/CancelBooking"
      )
  },
  mixins: [vueGoodTable],
  props: {
    detailRouteName: {
      type: String,
      default: "ManageBookingDetail"
    },
    getBookingsAPI: {
      type: Function,
      required: true
    },
    approveBookingAPI: {
      type: Function,
      required: true
    },
    rejectBookingAPI: {
      type: Function,
      required: true
    },
    cancelBookingAPI: {
      type: Function,
      required: true
    }
  },
  data: function () {
    return {
      bookingTableData: [],
      bookingTablePagination: {},
      bookingTableColumns: [
        {
          label: "",
          field: "action",
          width: "150px"
        },
        {
          label: "Project Unit",
          field: "project",
          width: "550px"
        },
        {
          label: "Buyer",
          field: "projectPurchaseReservation.purchaserName1"
        },
        {
          label: "Buyer contact",
          field: "buyerContact"
        },
        {
          label: "Agent",
          field: "assignedAgent"
        },
        {
          label: "Last Updated",
          field: "updatedAt",
          formatFn: (date) => this.$moment(date).format("LLL")
        }
      ],
      tableParams: {
        page: 1,
        perPage: 30
      },
      isFilterActive: false,
      filter: {
        "name[partial]": ""
      },

      isLoading: false,
      approveBookingModal: {
        id: "",
        isShown: false
      },
      rejectBookingModal: {
        id: "",
        isShown: false
      },
      cancelBookingModal: {
        id: "",
        isShown: false
      }
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {
    this.init();
  },
  methods: {
    async init() {
      await this.getAllBookings();
    },
    // ===================== Table related methods =====================
    updateParams(newProps) {
      this.tableParams = Object.assign({}, this.tableParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.getAllBookings();
    },

    onPerPageChange(params) {
      this.$store.commit("setIsLoading", true);
      this.updateParams({ perPage: params.currentPerPage });
      this.getAllBookings().then(() => {
        this.$store.commit("setIsLoading", false);
      });
    },
    filterToggle() {
      this.isFilterActive = !this.isFilterActive;
    },
    toBookingDetail(event) {
      let bookingId = event.row.id;
      this.$router.push({
        name: this.detailRouteName,
        params: { id: bookingId }
      });
    },
    // ===================== Filter related methods =====================
    filterData() {
      this.tableParams.page = 1;
      // Trim filter
      this.filter = this._.mapValues(this.filter, (item) => item.trim());
      this.getAllBookings();
    },
    clearFilter() {
      this.tableParams.page = 1;
      this.filter = this._.mapValues(this.filter, () => "");
      this.getAllBookings();
    },
    // ========================== API Related =========================
    async getAllBookings() {
      this.isLoading = true;
      try {
        // let filteredParam = this.$cleanQueryParam(this.filter);

        let data = await this.getBookingsAPI(
          projectBookingStatus.BOOKED_BY_OTHER,
          {
            page: this.tableParams.page,
            perPage: this.tableParams.perPage
          }
        );

        this.bookingTableData = this._.cloneDeep(data.data);
        this.bookingTablePagination = this._.cloneDeep(data.meta.pagination);
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to get bookings list. Please try again later."
        });
      }
    },
    openApproveModal(id) {
      this.approveBookingModal.id = id;
      this.approveBookingModal.isShown = true;
    },
    async approveBooking(id, payload) {
      try {
        this.$store.commit("setIsLoading", true);
        await this.approveBookingAPI(id, payload);
        await this.getAllBookings();

        this.approveBookingModal.isShown = false;
        this.$notify({
          group: "alert",
          type: "success",
          title: "Success",
          text: "Booking has been approved successfully."
        });
        this.$store.commit("setIsLoading", false);
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to approve booking. Please try again later."
        });
      }
    },
    openRejectModal(id) {
      this.rejectBookingModal.id = id;
      this.rejectBookingModal.isShown = true;
    },
    async rejectBooking(id, payload) {
      try {
        this.$store.commit("setIsLoading", true);
        await this.rejectBookingAPI(id, payload);
        await this.getAllBookings();

        this.rejectBookingModal.isShown = false;
        this.$notify({
          group: "alert",
          type: "success",
          title: "Success",
          text: "Booking has been rejected successfully."
        });
        this.$store.commit("setIsLoading", false);
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to reject booking. Please try again later."
        });
      }
    },
    openCancelModal(id) {
      this.cancelBookingModal.id = id;
      this.cancelBookingModal.isShown = true;
    },
    async cancelBooking(id, payload) {
      try {
        this.$store.commit("setIsLoading", true);
        await this.cancelBookingAPI(id, payload);
        await this.getAllBookings();

        this.cancelBookingModal.isShown = false;
        this.$notify({
          group: "alert",
          type: "success",
          title: "Success",
          text: "Booking has been cancelled successfully."
        });
        this.$store.commit("setIsLoading", false);
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to cancel booking. Please try again later."
        });
      }
    }
  }
};
</script>

<style lang="scss">
.manage-booking-table {
  .project-detail {
    img.main-photo {
      @include image(cover);
      width: 150px;
      height: 100px;
      border-radius: 5px;
      box-shadow: 0px 2px 5px #00000030;
    }

    .title {
      font-weight: bold;
      font-size: 18px;
      align-items: flex-end;

      .plot {
        font-size: 16px;
        border: solid 1px $color-accent2;
        color: $color-accent2;
        border-radius: 4px;
        padding: 2px 10px;
        text-align: center;
      }
    }
    .context {
      color: #656565;
    }
  }
}
</style>
